/* src/pages/ProjectDetail.css */
@import "~react-image-gallery/styles/css/image-gallery.css";

/* General container styling for the project detail */
.project-detail {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background-color: #181818; /* A dark background for elegance */
    color: #fff; /* White text for contrast */
}

/* Styling the project title */
.project-detail h1 {
    font-size: 2rem;
    margin-bottom: 10px;
}

/* Image container to control the size and centering */
.project-detail .image-container {
    max-width: 600px;
    width: 100%;
    margin: 20px 0;
}

.project-detail .image-container img {
    width: 100%; /* Image will fit within the container */
    border-radius: 8px; /* Rounded corners for softness */
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); /* Adding some depth */
}

/* Styling for project text details */
.project-detail .project-info {
    text-align: left;
    max-width: 600px;
    width: 100%;
}

.project-detail .project-info p {
    font-size: 1rem;
    margin-bottom: 10px;
}

/* Link styling */
.project-detail .project-info a {
    color: #1e90ff;
    text-decoration: none;
}

.project-detail .project-info a:hover {
    text-decoration: underline;
}