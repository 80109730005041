/* src/pages/Home.css */
.home-container {
  padding: 40px;
  max-width: 1200px;
  margin: 0 auto;
  font-family: 'Arial', sans-serif;
}

.intro-section, .projects-section, .contact-section {
  margin-bottom: 60px;
}

h1, h2, h3 {
  color: #ffffff;
  margin-bottom: 20px;
}

h1 {
  font-size: 2.5em;
  text-align: center;
}

h2 {
  font-size: 2em;
  border-bottom: 2px solid #007BFF;
  padding-bottom: 10px;
}

h3 {
  font-size: 1.5em;
}

p {
  color: #ffffff;
  line-height: 1.6;
  font-size: 1.1em;
}

ul {
  list-style-type: none;
  padding: 0;
}

.project-preview {
  background: #f4f4f4;
  margin: 20px 0;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.project-preview:hover {
  transform: translateY(-5px);
}

.project-preview img {
  max-width: 100%;
  height: auto;
  margin-top: 20px;
  border-radius: 5px;
}

a {
  color: #ffffff;
  text-decoration: none;
  transition: color 0.3s ease;
}

a:hover {
  text-decoration: underline;
  color: #0056b3;
}

.view-all-projects, .view-project {
  display: inline-block;
  margin-top: 20px;
  padding: 12px 24px;
  background-color: #007BFF;
  color: #fff;
  border-radius: 5px;
  text-align: center;
  transition: background-color 0.3s ease;
}

.view-all-projects:hover, .view-project:hover {
  background-color: #0056b3;
}

.contact-section a {
  color: #007BFF;
  text-decoration: none;
  transition: color 0.3s ease;
}

.contact-section a:hover {
  text-decoration: underline;
  color: #0056b3;
}

/* Feedback Form Styles */
.feedback-form-container {
  max-width: 600px;
  margin: 0 auto;
}

.form-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.form-group label {
  margin-bottom: 5px;
  font-weight: bold;
}

.form-group input,
.form-group textarea {
  background: #4d4d4d;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1em;
}

.form-group textarea {
  resize: vertical;
}

button[type="submit"] {
  padding: 12px 24px;
  background-color: #007BFF;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1em;
  transition: background-color 0.3s ease;
}

button[type="submit"]:hover {
  background-color: #0056b3;
}
