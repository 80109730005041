/* src/pages/Admin.css */
.admin-container {
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
  font-family: 'Arial', sans-serif;
}

.admin-title {
  font-size: 2em;
  color: #ffffff;
  margin-bottom: 20px;
  text-align: center;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
}

.admin-form {
  margin-bottom: 40px;
  background: #30303b;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  animation: fadeIn 0.5s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.form-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.form-group label {
  margin-bottom: 5px;
  font-weight: bold;
  color: #ffffff;
}

.form-group input,
.form-group textarea {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1em;
  transition: border-color 0.3s;
}

.form-group input:focus,
.form-group textarea:focus {
  border-color: #007BFF;
  outline: none;
}

.form-group textarea {
  resize: vertical;
}

.form-group input[type="file"] {
  padding: 5px 0;
}

.submit-button {
  padding: 10px 20px;
  background-color: #007BFF;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1em;
  transition: background-color 0.3s, transform 0.3s;
}

.submit-button:hover {
  background-color: #0056b3;
  transform: translateY(-2px);
}

.button-group {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: 20px;
}

.overlay-button {
  padding: 10px 20px;
  background-color: #28a745;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1em;
  transition: background-color 0.3s, transform 0.3s;
}

.overlay-button:hover {
  background-color: #218838;
  transform: translateY(-2px);
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  animation: fadeIn 0.5s ease-in-out;
}

.overlay-content {
  background: #000000;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2); /* Boost the shadow for more contrast */
  width: 90%;  /* Increase the width */
  max-width: 800px; /* Maintain a max width */
  max-height: 80%;
  overflow-y: auto;
  position: relative;
  animation: slideIn 0.5s ease-in-out;
}

/* Smooth scrolling for long feedback lists */
.overlay-content {
  scroll-behavior: smooth;
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 5px 10px;
  background-color: #dc3545;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
}

.close-button:hover {
  background-color: #c82333;
  transform: scale(1.1);
}

/* Add a subtle, modern shadow behind the section title */
.section-title {
  font-size: 1.8em;
  margin-bottom: 20px;
  text-align: center;
  border-bottom: 2px solid #007BFF;
  padding-bottom: 10px;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Fancy shadow */
}

/* Project-list and feedback-list */
.project-list,
.feedback-list {
  margin-top: 20px;
}

/* Project-item (Remains unchanged) */
.project-item {
  background: #30303b;
  margin: 10px 0;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: transform 0.3s;
}

.project-item:hover {
  transform: translateY(-5px);
}

.project-item h3 {
  color: #ffffff;
  margin-bottom: 10px;
}

.project-item p {
  color: #ffffff;
}

.project-actions {
  display: flex;
  gap: 10px;
}

/* Feedback-item with the new changes */
.feedback-item {
  background: #30303b;
  margin: 15px 0; /* More margin to make the items more spaced out */
  padding: 20px;  /* Increase padding for better text breathing space */
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  transition: transform 0.3s, box-shadow 0.3s;
}

.feedback-item {
  opacity: 0;
  transform: translateY(15px);
  animation: fadeInUp 0.5s ease forwards;
}

/* Keyframe for the smooth fade-in and translate-up */
@keyframes fadeInUp {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Slight hover effect to make the feedback items interactive */
.feedback-item:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Make the hover shadow more pronounced */
}

/* Feedback Header - holds name and email */
.feedback-header {
  display: flex;
  flex-direction: column;
  margin-right: 20px;  /* Push the content further so it's not cramped with Delete button */
  width: 100%; /* Allow it to use the full width */
}

/* Name and email spacing and font sizes */
.feedback-item h3 {
  color: #ffffff;
  margin: 0 0 5px 0; /* Add space below the name */
  font-size: 1.25em; /* Slight increase in the font */
  font-weight: 600; /* Make the name bold */
}

/* Apply some margin where the message starts further down */
.feedback-item p {
  color: #ffffff;
  margin-top: 10px; /* Add more spacing after the email */
  margin-bottom: 0;
  max-width: 80%; /* Constrain the message width */
  word-wrap: break-word;
  line-height: 1.4em; /* Improve readability */
}

.email {
  font-size: 1em; /* Make the email font slightly larger */
  color: #ffffff;
  margin: 0;
}

.feedback-list {
    padding: 20px;
}

/* Margin between feedback items */
.feedback-list .feedback-item + .feedback-item {
  margin-top: 15px; /* Extra space between feedback items */
  border-top: 1px solid #eee;  /* Nice separator between items */
  padding-top: 15px; /* Make the padding consistent after separator */
}

.feedback-actions {
  display: flex;
  align-items: flex-start; /* Pushes the delete button to align at the top */
}

/* Delete button (shared between project and feedback) */
.delete-button {
  padding: 7px 14px; /* Larger padding for the Delete button */
  background-color: #dc3545;
  color: #fff;
  border: none;
  border-radius: 8px; /* Slightly more rounded for a modern feel */
  font-size: 0.9em; /* Keep the text readable */
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s, box-shadow 0.3s;
  align-self: flex-start;
}

/* Subtle hover transformation for the Delete button */
.delete-button:hover {
  background-color: #c82333;
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(220, 53, 69, 0.4); /* Soft glow on hover */
}

/* Edit link */
.edit-link {
  color: #007BFF;
  text-decoration: none;
  transition: color 0.3s;
}

.edit-link:hover {
  text-decoration: underline;
  color: #0056b3;
}

/* Error message */
.error-message {
  color: #dc3545;
  margin-bottom: 20px;
  text-align: center;
}

