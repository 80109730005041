/* EditProject.css */
.edit-project {
    padding: 20px;
}

.edit-project h1 {
    font-size: 2em;
    margin-bottom: 20px;
}

.edit-project label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
}

.edit-project input,
.edit-project textarea {
    width: 100%;
    padding: 8px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.edit-project button {
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.edit-project button:hover {
    background-color: #0056b3;
}