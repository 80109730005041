/* src/pages/Projects.css */

body {
  background-color: #1a1a1a;
}

.projects-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 30px;
  padding: 40px;
  background: transparent;
  border-radius: 12px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.project-thumbnail-container {
  width: 100%;
  height: 300px;
  text-align: center;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 2px solid #3498db;
  border-radius: 12px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease-in-out, border-color 0.3s ease-in-out;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  position: relative;
}

.project-thumbnail-container:hover {
  transform: scale(1.05);
  border-color: #2ecc71;
}

.project-thumbnail-content {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.project-thumbnail-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease-in-out;
}

.project-thumbnail-container:hover .project-thumbnail-image {
  transform: scale(1.1);
}

.project-thumbnail-title {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  background: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 15px;
  font-size: 1.2rem;
  text-align: center;
  transition: background 0.3s ease-in-out, color 0.3s ease-in-out;
}

.project-thumbnail-title a {
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
  color: white;
  text-decoration: none;
  background-image: linear-gradient(to right, #3498db, #2ecc71);
  background-size: 0% 2px;
  background-repeat: no-repeat;
  background-position: bottom left;
  transition: background-size 0.3s ease-in-out, color 0.3s ease-in-out;
}

.project-thumbnail-container:hover .project-thumbnail-title a {
  background-size: 100% 2px;
  color: #2ecc71;
}

.project-thumbnail-fallback {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(240, 240, 240, 0.2);
  color: #888;
  font-style: italic;
  backdrop-filter: blur(5px);
}
