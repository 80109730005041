/* Backdrop when modal is open */
.confirmation-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.6);  /* Slightly transparent background */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;  /* Ensure this is on top of everything */
}

/* Modal dialog styling */
.confirmation-modal {
    background: white;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    z-index: 10000;  /* Ensure modal content is on top */
}

/* Confirmation modal actions */
.confirmation-actions {
    margin-top: 20px;
}

.confirm-button, .cancel-button {
    padding: 8px 16px;
    margin: 0 8px;
    cursor: pointer;
}

.confirm-button {
    background-color: #f44336;
    border: none;
    color: white;
}

.cancel-button {
    background-color: #4caf50;
    border: none;
    color: white;
}
